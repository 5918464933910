<template>
  <a
    class="atom-social-share"
    :href="selectedNework.shareUrl"
    :aria-label="`Share with ${capitalizedNetwork}`"
    target="_blank"
    rel="noopener noreferrer"
  >
    <slot />
  </a>
</template>

<script setup>
const props = defineProps({
    network: { type: String, required: true },
    styled: { type: Boolean, default: undefined },
    label: { type: Boolean, default: undefined },
    url: { type: String, default: undefined },
    title: { type: String, default: undefined },
    user: { type: String, default: undefined },
    hashtags: { type: String, default: undefined },
    image: { type: String, default: undefined },
});

const selectedNework = useSocialShare({
    network: props.network,
    url: props.url,
    title: props.title,
    user: props.user,
    hashtags: props.hashtags,
    image: props.image,
});

const capitalizedNetwork = props.network.charAt(0).toUpperCase() + props.network.slice(1);
</script>

<style lang="scss">
.atom-social-share {
    display: block;
}
</style>
