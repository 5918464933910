<template>
  <p class="atom-lead">
    {{ text }}
  </p>
</template>

<script setup>
const props = defineProps({
    colorSchema: {
        type: String,
        default: 'petrol',
        validator: (value) => ['petrol', 'red', 'blue', 'orange', 'green', 'coral', 'mustard', 'white'].includes(value),
    },

    text: {
        type: String,
        required: true,
        default: '',
        validator: (value) => value.length > 0,
    },
});

const propsColor = computed(() => (
    props.colorSchema === 'white'
        ? 'var(--c-white)'
        : `var(--c-${props.colorSchema}-1)`
));

const colorTopLevel = inject('colorSchema');

const colorMain = computed(() => (
    colorTopLevel.value === 'petrol'
        ? propsColor.value
        : `var(--c-${colorTopLevel.value}-1)`
));
</script>

<style lang="scss" scoped>
.atom-lead {
    color: v-bind(colorMain);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--lead);
    line-height: var(--l-height--lead);

    &.layout-news__hero-article-lead {
        font-family: var(--f-family--thin);
    }

    &.block-hero__lead  {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }
}
</style>
